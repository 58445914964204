/** @jsxImportSource @emotion/react */
import {useState, useEffect, useRef} from 'react'
import tw, { styled } from 'twin.macro'
import SignatureCanvas from 'react-signature-canvas'
import {H2, StyledCheckbox} from './StyledComponents'
const Disclosures = ({info, setInfo}) => {
    const sigCanvas = useRef(null)
    console.log({ info })
    if(!info) return <></>

    const [checkboxesBySort, setCheckboxesBySort] = useState([])
    useEffect(() => {


    }, [info])

  return (
    <div tw="border-t border-gray-900/10 pt-12 mt-16">
      <H2>Disclosures from Goodleap</H2>
      {info.sections
        .filter(section => {
          // TODO CARL look at this
          return !section.text.includes('State Specific Disclosures')
        })
        .map((section, index) => (
          <div key={index} tw="mt-5 space-y-5">
            <fieldset>
              {section.text && !section.checkBox ? (
                section.boldText ? (
                  <legend tw="text-sm font-semibold leading-6 text-gray-900">
                    {section.text}
                  </legend>
                ) : (
                  <legend tw="text-sm leading-6 text-gray-900">
                    {section.text}
                  </legend>
                )
              ) : (
                <></>
              )}
            </fieldset>
            {section.text && section.checkBox ? (
              <fieldset>
                <div tw="relative flex gap-x-3">
                  <div tw="flex h-6 items-center">
                    <StyledCheckbox
                      type="checkbox"
                      isRequired
                      isEmpty={true}
                    />
                  </div>
                  <div tw="text-sm leading-6">
                    <label
                      htmlFor="comments"
                      tw="text-sm leading-6 text-gray-900"
                    >
                      {section.text}
                    </label>
                  </div>
                </div>
              </fieldset>
            ) : (
              <></>
            )}

            {section.signatureBox ? (
              <div tw="mt-5 bg-white border-2 rounded-md w-fit">
                <SignatureCanvas
                  ref={sigCanvas}
                  penColor="blue"
                  canvasProps={{
                    width: 500,
                    height: 300,
                    className: 'sigCanvas',
                  }}
                  onEnd={
                    () =>
                      setInfo({
                        ...info,
                        signatureImage: sigCanvas.current.toDataURL(),
                      }) // TODO CARL this is the signature box
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        ))}
    </div>
  )
}

export default Disclosures
